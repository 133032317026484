<template>
  <div class="article">
    <div class="left">
      <h3 class="title">{{ params.version }}</h3>
      <div class="content" v-html="params.content"></div>
    </div>
    <div class="right">
      <el-form label-position="right" :model="params" :rules="rules" ref="ruleParams" label-width="80px">

        <el-form-item label="版本号" prop="version">
          <el-input v-model="params.version" placeholder="请输入版本号" v-if="$route.query.id" :disabled="true"></el-input>
          <el-input v-model="params.version" placeholder="请输入版本号" v-else></el-input>
        </el-form-item>

        <el-form-item label="简介" prop="intro">
          <el-input v-model="params.intro" placeholder="请输入公司简介" ></el-input>
        </el-form-item>

        <el-form-item label="更新内容">
          <vue-html5-editor :auto-height="true" :content="params.content" :height="400" @change="showEditorContent">
          </vue-html5-editor>
        </el-form-item>
        <el-form-item>
          <el-button @click="$router.push('/qllcompany/replace')">取消</el-button>
          <el-button class="btngx" type="primary" @click="add()">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
  import VueHtml5Editor from 'vue-html5-editor'
  Vue.use(VueHtml5Editor, {
    name: 'vue-html5-editor',
    showModuleName: false,
    language: "zh-cn",
    visibleModules: [
      "text",
      "color",
      "font",
      "align",
      "list",
      "link",
      "unlink",
      "tabulation",
      "image",
      "hr",
      "eraser",
    ],


  })
  export default {
    data() {
      return {
        params: {
          version: '', //版本号
          content: '', //更新内容
          intro:'',//公司简介
        },
        rules: {
          version: [{
              required: true,
              message: '请输入版本号',
              trigger: 'blur'
            },
            {
              min: 2,
              max: 10,
              message: '长度在 2 到 10 个字符',
              trigger: 'blur'
            }
          ],
          content: [{
            required: true,
            message: '请输入版本号',
            trigger: 'blur'
          }, ],
        }
      }
    },
    created() {
      //排序
      let list = [1, 2, 3, 4, 6, 5, 8, 7, 2, 3];
      // for (let i = 0; i< list.length; i++) {
      //   for (let j = 0; j < list.length-i - 1; j++){
      //     if (list[j] > list[j+1]) {
      //       let a = list[j]
      //       list[j]= list[j+1];
      //       list[j+1] = a;
      //     }
      //  }
      // }
      // list = list.filter((e,index,self)=>{
      //   return self.indexOf(e) ===index
      // })
      // let arr = [];
      // for(let i = 0;i < list.length; i ++) {
      //   console.log(list[i]);
      //   if(arr.indexOf(list[i]) ==-1){
      //     arr.push(list[i])
      //   }
      // }
      // console.log(arr);


      if (this.$route.query.id) {
        this.getList(this.$route.query.id);
      }
    },
    methods: {
      async getList(id) {
        let params = {
          id: id
        }
        let res = await this.$ZGManager.versionInfo(params);
        if (res.status !== 200) return;
        console.log(res);
        this.params.version = res.data.list[0].version;
        this.params.content = res.data.list[0].content;
        this.params.intro = res.data.list[0].intro;
      },
      // 处理vue-html5-editor的内容并展示
      showEditorContent(data) {
        console.log(data);
        this.params.content = data;
      },


      add() {
        let dialog = this.$confirm('是否确认保存版本', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$refs.ruleParams.validate(async (valid) => {
            if (valid) {
              console.log(this.params);
              let query = {
                content: this.params.content,
                version: this.params.version,
                intro:this.params.intro,
                status: 1,
              }
              console.log(query);
              if (this.$route.query.id) {
                delete query.version
                let res = await this.$ZGManager.versionInfoUpdate(this.$route.query.id, query);
                console.log(res);
                let timer = setTimeout(() => {
                  window.close()
                }, 1000)

                if (res.status !== 200) return;
                this.$message.success('修改成功')
              } else {
                let params = {
                  version :query.version,
                  content : query.content,
                  intro   : query.intro,
                }
                let res = await this.$ZGManager.versionInfoAdd(params);
                console.log(res);
                if (res.status !== 200) return;
                if (res.data == '不能输入相同的版本号') {
                  this.$message.warning('版本号已存在')
                  return
                }
                this.$message.success('更新成功')
                let timer = setTimeout(() => {
                  window.close()
                }, 1000)
              }
            } else {
              console.log('error');
              return false;
            }
          });
        }).catch(() => {
          this.$message.info('已取消更新')
        });
        if (dialog.action != 'confirm') return;
      }

    }
  }
</script>
<style lang="scss" scoped>
  .left {
    width: calc(375px - 0.6rem);
    height: calc(100% - 0.6rem);
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: auto;
    float: left;
    position: relative;
    padding: 0.3rem;

    .title {
      font-size: 20px;
      font-weight: bold;
      color: #111;
    }

    .content {
      margin-top: 0.3rem;
      // width: calc(375px - 0.6rem);
      overflow-x: hidden;
      // overflow-y: auto;
      img {
        max-width: calc(375px - 0.6rem);
      }
    }
  }

  .right {
    width: calc(100% - 405px);
    height: calc(100% - 20px);
    float: right;
    background-color: #fff;
    padding: 10px;
    margin-left: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: auto;
    img{
      width: 100%;
      height: 100%;
    }
  }



  // .content {
  //   padding: 10px;
  //   background-color: #fff;
  //   // margin-bottom: 50px;
  //   font-size: 14px;

  //   .header {
  //     padding: 40px 70px;

  //     i {
  //       padding: 0 5px;
  //     }

  //     span {
  //       cursor: pointer;
  //     }
  //   }

  //   .form-content {
  //     margin: 40px 0 0 220px;

  //     button {
  //       margin-top: 30px;
  //     }

  //     .btngx {
  //       margin-left: 180px;
  //     }
  //   }
  // }

  ::v-deep .el-textarea__inner {
    height: 190px !important;
  }
</style>